import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import HubspotForm from 'react-hubspot-form'

import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"

const RMAFormPage = () => {
  const data = useStaticQuery(graphql`
    query RMAFormPageeQuery {
      prismicFormsPage {
        data {
          fourth_form_title
          fourth_form_description
          fourth_form_hubspot_id
        }
      }
    }
  `)

  const forms = data.prismicFormsPage.data

  return (
    <MainContainer>
      <HeadDataNew
        title={forms.fourth_form_title}
        description={forms.fourth_form_description}
      />

      <div className='bg-white py-12'>
        <div className='w-11/12 max-w-screen-lg mx-auto'>
          <h1 className='font-display font-extrabold text-4xl md:text-5xl text-red-900 text-center tracking-wider leading-tight mb-6'>
            {forms.fourth_form_title}
          </h1>

          <p className='text-gray-900 text-center font-semibold mt-6 mb-12'>{forms.fourth_form_description}</p>

          <div className='bg-white md:w-7/12 mx-auto'>
            <HubspotForm
              portalId='9298297'
              formId={forms.fourth_form_hubspot_id}
              loading={<div className='text-2xl italic font-bold text-center text-gray-500'>Loading Request Form...</div>}
            />
          </div>
        </div>
      </div>
    </MainContainer>
  )
}

export default RMAFormPage